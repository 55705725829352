<template>
  <div>
    <b-table
      striped
      hover
      :items="postDataList"
      :fields="fields"
      :busy="isLoadingComponent"
      show-empty
      responsive
    >
      <template #table-busy>
        <div class="text-center text-secondary my-2">
          <b-spinner class="align-middle"></b-spinner>
          <strong>Loading...</strong>
        </div>
      </template>

      <template v-slot:cell(no)="{ index }">
        {{ (currentPageList - 1) * 10 + index + 1 }}
      </template>

      <template v-slot:cell(created_at)="{ item }">
        {{ moment(item.created_at).locale("id").format("DD MMMM YYYY") }}
      </template>

      <template v-slot:cell(status)="{ item }">
        <b-badge variant="light-success" v-if="item.status === 'publish'">
          Publish
        </b-badge>
        <b-badge variant="light-warning" v-else> Waiting Approval </b-badge>
      </template>

      <template v-slot:cell(category)="{ item }">
        {{ item.category ? item.category.name : "-" }}
      </template>

      <template v-slot:cell(creator)="{ item }">
        {{ item.creator ? item.creator.name : "-" }}
      </template>

      <template v-slot:cell(actions)="{ item }">
        <b-dropdown variant="danger" size="sm">
          <template #button-content> Action </template>

          <b-dropdown-item :to="'/edit-post/' + item.uuid">
            <feather-icon icon="EditIcon" class="mr-75" />
            Update
          </b-dropdown-item>

          <b-dropdown-item @click="deleteItem(item.uuid)">
            <feather-icon icon="TrashIcon" class="mr-75" />
            Delete
          </b-dropdown-item>
        </b-dropdown>
      </template>
    </b-table>

    <div
      v-if="resultData.itemsPerPage > 0"
      class="mt-3 d-flex justify-content-between align-items-center"
    >
      <small
        >Showing {{ (resultData.currentPage - 1) * 10 + 1 }} to
        {{ (resultData.currentPage - 1) * 10 + 1 * resultData.itemCount }} from
        {{ resultData.totalItems }}</small
      >
      <b-pagination
        class="justify-content-end"
        v-model="currentPageList"
        :total-rows="resultData.totalItems"
        :per-page="resultData.itemsPerPage"
        aria-controls="my-table"
        @change="getData"
      ></b-pagination>
    </div>
  </div>
</template>

<script>
import moment from "moment";
import vSelect from "vue-select";
import { quillEditor } from "vue-quill-editor";
import {
  ValidationProvider,
  ValidationObserver,
  configure,
} from "vee-validate";
import { required } from "@validations";
import Ripple from "vue-ripple-directive";
configure({
  classes: {
    valid: "is-valid",
    invalid: "is-invalid",
    dirty: ["is-dirty", "is-dirty"], // multiple classes per flag!
    // ...
  },
});
import {
  BTable,
  BSpinner,
  BBadge,
  BDropdown,
  BPagination,
  BDropdownItem,
  BButton,
  BFormFile,
  BFormRadioGroup,
  VBModal,
} from "bootstrap-vue";
import _ from "lodash";
export default {
  components: {
    BTable,
    BSpinner,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    BButton,
    BFormFile,
    vSelect,
    quillEditor,
    BFormRadioGroup,
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    "b-modal": VBModal,
    Ripple,
  },
  props: {
    postData: {
      type: "",
    },
    formPayload: {
      type: "",
    },
    validations: {
      type: "",
    },
    editId: {
      type: "",
    },
    result: {
      type: "",
    },
    isLoading: {
      type: Boolean,
    },
    currentPage: {
      type: Number,
    },
    getData: {
      type: Function,
    },
    deleteItem: {
      type: Function,
    },
  },
  watch: {
    isLoading(value) {
      this.isLoadingComponent = value;
    },
    postData(value) {
      this.postDataList = value;
    },
    formPayload(value) {
      this.formPayloadPost = value;
    },
    validations(value) {
      this.validationsForm = value;
    },
    currentPage(value) {
      this.currentPageList = value;
    },
    result(value) {
      this.resultData = value;
    },
    editId(value) {
      this.editIdModal = value;
    },
  },
  data() {
    return {
      moment,
      required,
      isLoadingComponent: true,
      currentPageList: 1,
      resultData: {},
      postDataList: [],
      editIdModal: null,
      formPayloadPost: {
        name: "",
      },
      file_type: "",
      file_url: "",
      file: "fileContent",
      validationsForm: "",
      category: [],
      categorySelected: [],
      fields: [
        { key: "no" },
        { key: "created_at", label: "Tanggal Submit" },
        { key: "title", label: "Judul" },
        { key: "seo_title", label: "SEO Title" },
        { key: "status" },
        { key: "category", label: "Kategori" },
        { key: "creator" },
        { key: "actions" },
      ],
      //   filter: {
      //     order_type: "desc",
      //   },
    };
  },
  computed: {
    rows() {
      return this.postDataList.length;
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/quill.scss";
@import "@core/scss/vue/libs/vue-select.scss";

.ql-editor.ql-blank {
  height: 400px;
}
.media_file {
  width: 400px;
  height: 300px;
}
.image__hint {
  font-size: 10px;
  margin-bottom: 0;
}
</style>
